import { RotatingLines } from 'react-loader-spinner';
import css from "./Spinner.module.css";

export const Spinner = ({classSpinner}) => {
    return (
        <div className={classSpinner ? `${css.spinner} ${classSpinner}` : css.spinner}>
            <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeColor="grey"
                strokeWidth="3"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
            />
        </div>
    )
};