import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import Products from "../Data/Product.json";
import { useMemo } from "react";
import css from "./PortfolioItem.module.css";
import { IoHandLeftOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Modal} from "../components/Modal/Modal";
import { MarkdownDisplay } from 'components/MarkdownDisplay/MarkdownDisplay'


export const PortfolioItem = () => {

    const {item} = useParams();
    const product = useMemo( () => Products.find(({id}) => id === item), [item]);
    const { t, i18n } = useTranslation();

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "block"}}
            onClick={onClick}
            />
        );
        }     
        function SamplePrevArrow(props) {
            const { className, style, onClick } = props;
            return (
            <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
            />
        );
        }
        
    const settings = {
        centerMode: true,
        centerPadding: '20px',
        slidesToShow: 2,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                arrows: true,
                slidesToShow: 1.75,
                },
            },
            {
                breakpoint: 480,
                settings: {
                arrows: true,
                slidesToShow: 1,
                },
            },
            ],
        };

    const renderSlides = product.screenshots.map((screenshot, index) => (
        <div key={index} className={css.slide} onClick={() => openModal(screenshot)}>
            <img src={require(`../assets/Poftfolio/${product.id}/${screenshot}`)} alt={product.rating_name} className={css.concretization_image} />
        </div>
    ));

    let markdownFile = "/locales/" + product.description[i18n.language];

    let otherLanguages;
    if (product.other_languages === "0") {
        otherLanguages = <p className={css.item_other}> </p>
    } else {
        otherLanguages = <p className={css.item_other}>+{product.other_languages} {t("portfolio_item.quantity")}</p>;
    }

    return (
        <section className={css.portfolioItem}>
        <div className={css.portfolioItem_content}>
        <div className={css.portfolioItem_primary}>
            <div className={css.primary_general}>
                <img src={require(`../assets/Poftfolio/${product.id}/${product.icon}`)} alt={product.name} className={css.general_icon}></img>
                <div className={css.general_block}>
                    <h2 className={css.general_title}>{product.name}</h2>
                    <p>{product.category_icon.map((icon, index) =>
                            <img key={index} src={require(`../assets/Poftfolio/filters/${icon}`)} alt={product.category} className={css.concretization_icon}></img>
                        )}
                    </p>
                    <a href={product.download_link} className={css.general_link}>{t("portfolio_item.button")}</a>
                </div>  
            </div>
            <ul className={css.primary_list}>
                <li className={css.primary_item}>
                    <p className={css.item_title}>{t("portfolio_item.language")}</p>
                    <p className={css.item_content}>{product.main_language}</p>
                    {otherLanguages}
                </li>
                <li  className={css.primary_item}>
                    <p className={css.item_title}>{t("portfolio_item.dimension")}</p>
                    <p className={css.item_content}>{product.size}</p>
                    <p className={css.item_other}>{t("portfolio_item.unit")}</p>
                </li>
            </ul>
        </div>
        <div className={css.primary_concretization}>
            <div className={css.concretization_slider}>
                <Slider {...settings}>{renderSlides}</Slider>
            </div>
            {isModalOpen && (
                <Modal
                images={product.screenshots.map((screenshot) => require(`../assets/Poftfolio/${product.id}/${screenshot}`))}
                closeModal={closeModal}
                />
            )}
            <MarkdownDisplay filePath={markdownFile} />
        </div>
        <div className={css.primary_rating}>
            <h3 className={css.rating_title}>{t("portfolio_item.grade")}</h3>
            <img src={require(`../assets/Poftfolio/raiting_icons/${product.icon_rating}`)} alt={product.rating_description[i18n.language]} className={css.rating_image}></img>
        </div>
        <div className={css.primary_information}>
            <h3 className={css.information_title}>{t("portfolio_item.info")}</h3>
            <ul className={css.information_list}>
                <li className={css.information_item}>
                    <p className={css.information_text}>{t("portfolio_item.author")}</p>
                    <p className={css.information_text}>© {new Date().getFullYear()} {t("portfolio_item.company")}</p>
                </li>
                <li className={css.information_item}>
                    <a href={product.privacy_policy_link} className={css.information_link}>
                    <IoHandLeftOutline className={css.information_icon}/>{t("portfolio_item.privacy")}</a>
                </li>
            </ul>
        </div>
    </div>
    </section>
)
}