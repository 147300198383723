import React, { useState } from "react";
import css from "components/ProductFilter/ProductFilter.module.css";

import { TfiSearch } from 'react-icons/tfi';
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useMemo } from "react";
import { Toggler } from '../Toggler/Toggler';
import { Spinner }  from "../Spinner/Spinner";
import { useTranslation } from 'react-i18next';
import 'utils/18n';

import Product from "Data/Product.json";

import All from "assets/Poftfolio/filters/icon_all.svg"
import Mac from "assets/Poftfolio/filters/icon-product-mac.svg";
import Phone from "assets/Poftfolio/filters/icon-product-iphone.svg";
import Tv from "assets/Poftfolio/filters/icon-product-tv.svg";
import Other from "assets/Poftfolio/filters/icon_other.svg";

const CATEGORIES = {
    all: 0,
    desktop: 1,
    mobile: 2,
    tv: 3,
    other: 4
};

const MAP_CATEGORY_NAME = {
    [CATEGORIES.all]: "All",
    [CATEGORIES.desktop]: "Desktop",
    [CATEGORIES.mobile]: "Mobile",
    [CATEGORIES.tv]: "Mobile / TV",
    [CATEGORIES.other]: "Library / Script"
}

const FILTERS = [
    {
        category:CATEGORIES.all,
        icon: All,
        translateTag: "all"
    },
    {
        category:CATEGORIES.desktop,
        icon: Mac,
        translateTag: "desktop"
    },
    {
        category:CATEGORIES.mobile,
        icon: Phone,
        translateTag: "mobile"
    },
    {
        category:CATEGORIES.tv,
        icon: Tv,
        translateTag: "tv"
    },
    {
        category:CATEGORIES.other,
        icon: Other,
        translateTag: "other"
    }
]

export const ProductFilter = () => {

    const { t } = useTranslation();  
    const [isLoading, setIsLoading] = useState(false);
    const [showArchive, setShowArchive] = useState(false);
    const [category, setCategory] = useState("All");
    const [selectedCategory, setSelectedCategory] = useState("All");    
    const [search, setSearch] = useState("");


    const itemsWithoutCategory = useMemo(() => {
        let prepareProducts = Product;

        if(!showArchive) {
            prepareProducts = prepareProducts.filter((product) => !product.isArchive)
        }

        if(search) {
            prepareProducts = prepareProducts
            .filter((product) => product.name.toLowerCase().includes(search) ||(product.tags && product.tags.some((tag) => tag && tag.toLowerCase().includes(search))))
        }

        return prepareProducts;
    }, [showArchive, search]);

    const items = useMemo (() => {
        let prepareProducts = itemsWithoutCategory;

        let chooseCategory;

        if(category !== CATEGORIES.all) {
            chooseCategory = MAP_CATEGORY_NAME[category]
        }

    if (chooseCategory) {
        prepareProducts = prepareProducts.filter((product) => product.category === chooseCategory);
    }

    return prepareProducts;
    }, [itemsWithoutCategory, category])

    const viewFilters = useMemo(() => {
        return FILTERS.filter(({category}) => {
                if(CATEGORIES.all === category) {
                    return true
                }

                const categoryName = MAP_CATEGORY_NAME[category];

                return itemsWithoutCategory.some((item) => item.category === categoryName);
        })
    }, [itemsWithoutCategory])

    useEffect ( () => {
        setIsLoading(true);

    const idTimeout = setTimeout(() => {
            setIsLoading(false);
        }, 300);

        return () => {
            clearTimeout(idTimeout);
        }
    }, [items]);

    return (
        <section className={css.products}>
            <div className={css.products_content}>                
                <div className={css.products_block}>
                    <ul className={css.products_filter}>
                    {
                        viewFilters.map(({category: filterCategory, icon, translateTag}) => (
                            <li 
                                onClick={() => {
                                    setCategory(filterCategory);
                                    setSelectedCategory(filterCategory);
                                }}
                                key={translateTag} 
                                className={`${css.filter_item} ${filterCategory === selectedCategory ? css.active : ''}`}>
                                <div className={css.filter_icon}>
                                    <img className={css.filter_img} src={icon} alt="All" />
                                </div>
                                {t(`portfolio_filter.${translateTag}`)}
                            </li>
                        ))
                    }
                    </ul>
                    <div className={css.products_toggle}>
                        <p className={css.products_toggle_text}>{t("portfolio_filter.archive")}</p>
                        <Toggler
                            defaultChecked={showArchive}
                            onChange={(checked) => {
                                setShowArchive(checked);
                            }}
                        />
                    </div>
                    <div className={css.products_form}>
                    <button className={css.form_button} disabled>
                        <TfiSearch size={18} className={css.form_icon} />
                    </button>
                    <input className={css.form_text} type="text" placeholder={t("portfolio_filter.placeholder")} onChange={(e) => setSearch(e.target.value)} />
                </div>
                </div>
                <div className={css.products_wrapper}>
                    <ul className={css.products_list}>
                        {
                            !isLoading ? items.map((value) => (           
                                <li key={value.id} className={css.products_item} >
                                    <Link to={`/portfolio/${value.id}`} className={css.products_link}>
                                            <img src={require(`assets/Poftfolio/${value.id}/${value.icon}`)} alt={value.title} className={css.products_image}></img>
                                            <h3>{value.name}</h3>
                                    </Link>
                                </li>
                            )) : (  
                                    <div className={css.spinner_wrapper}>
                                        <Spinner classSpinner={css.spinner}/>
                                    </div>
                                )
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}