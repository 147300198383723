import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import css from 'components/Home/Gallery/Gallery.module.css';
import 'swiper/css';
import 'swiper/css/pagination';
import Screenshots from "Data/Gallary.json";
import { NavLink } from 'react-router-dom';

export const Gallery = () => {
    const breakpoints = {
        280: {
        slidesPerView: 1,
        spaceBetween: 0,
        },
        480: {
        slidesPerView: 1.5,
        spaceBetween: 0,
        },
        767: {
        slidesPerView: 2.3,
        spaceBetween: 0,
        },
        1440: {
        slidesPerView: 3,
        spaceBetween: 0,
        },
        };
    return (
        <section className={css.gallery}>
            <div className={css.gallery_content}>
                <Swiper
                    modules={[Pagination, Autoplay]}
                    slidesPerView={4}
                    spaceBetween={0}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3500
                    }}
                    pagination={{
                        clickable: true
                    }}
                    className={css.gallery_swiper}
                    breakpoints={breakpoints}
                >
                    { Screenshots.map((value) => (                
                        <SwiperSlide key ={value.id}>
                            <NavLink to="/portfolio">
                                    <div className={css.gallery_slide}>
                                    <img className={css.gallery_img} src={require(`assets/Gallery/${value.screen}`)} alt={value.name} />
                                </div>
                            </NavLink>
                        </SwiperSlide>                            
                        ))
                    }
                </Swiper>
            </div>
        </section>
    )
}