// ## MarkdownDisplay Component

// A React component to render and display Markdown content from a local file.

// Time Complexity: O(n)
// Space Complexity: O(1)

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import css from 'components/MarkdownDisplay/MarkdownDisplay.module.css';

/**
 * MarkdownDisplay Component
 * @param {string} filePath - The path to the local Markdown file.
 * @returns {JSX.Element} - React component for rendering Markdown.
 */
export const MarkdownDisplay = ({ filePath }) => {
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {

    const fetchMarkdownFile = async () => {
      try {
        const response = await fetch(filePath);
        const content = await response.text();
        setMarkdownContent(content);
      } catch (error) {
        console.error('Error fetching Markdown file:', error);
      }
    };

    fetchMarkdownFile();
  }, [filePath]);

  return (
    <div className={css.markdowncontainer}>
      <ReactMarkdown children={markdownContent}/>
    </div>
  );
};

// Example Usage:
// <MarkdownDisplay filePath="/path/to/your/file.md" />
