import React, { useState, useEffect } from 'react';
import css from  '../Toggler/Toggler.module.css'; 

export const Toggler = ({ defaultChecked, onChange }) => {
    const [isToggled, setIsToggled] = useState(defaultChecked || false);

    const handleToggle = () => {
        setIsToggled(!isToggled);
        onChange && onChange(!isToggled);
    };

    useEffect(() => {
        setIsToggled(defaultChecked || false);
    }, [defaultChecked]);

    return (
        <div className={`${css.toggler} ${isToggled ? css.toggled : ''}`} onClick={handleToggle}>
            <div className={css['toggle_switch']}></div>
        </div>
    );
};
