import { useTranslation } from 'react-i18next';

import css from "components/Information/Information.module.css"

import { BsGithub } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { AiOutlineFilePpt, AiOutlineFilePdf } from 'react-icons/ai';
import { MarkdownDisplay } from 'components/MarkdownDisplay/MarkdownDisplay'

import vitalii from "assets/About/vitalii.jpg"
import PortfolioPdf from "assets/Files/portfolio.pdf"
import ResumePdf from "assets/Files/resume.pdf"

export const Information = () => {

    const { t } = useTranslation();
    
    let markdownFile = "/locales/" + t("about.description");

    const iconSize = 32;

    return (
        <section className={css.person}>
            <ul className={css.person_content}>
                <li>
                    <img className={css.person_img} alt="Vitalii Foto" src={vitalii} />
                </li>
                <li className={css.person_info}>
                    <h2 className={css.person_name}>{t("about.name")}</h2>
                        <p className={css.person_experience}>{t("about.experience")}</p>
                        <MarkdownDisplay filePath={markdownFile} />
                        <ul className={css.person_list}>
                            <li className={css.person_link}>
                                <a href="https://github.com/IGRSoft"><BsGithub size={iconSize} /></a>
                            </li>
                            <li className={css.person_link}>
                                <a href="https://www.linkedin.com/in/vitaliip/"><BsLinkedin size={iconSize} /> </a>
                            </li>
                            <li className={css.person_link}>
                                <a href={ResumePdf}><AiOutlineFilePdf size={iconSize} /></a>
                            </li>
                            <li className={css.person_link}>
                                <a href={PortfolioPdf}><AiOutlineFilePpt size={iconSize} /></a>
                            </li>
                        </ul>
                </li>
            </ul>
        </section>
    )
}