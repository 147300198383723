import css from 'components/Home/Feedback/Feedback.module.css';
import Feedbackinfo from 'Data/Feedback.json';
import { useTranslation } from 'react-i18next';
import 'utils/18n';

export const Feedback = () => {

    const { t, i18n } = useTranslation();

    return (
        <section className={css.feedback}>
            <div className={css.feedback_content}>
                <h3 className={css.feedback_title}>{t("feedback.title")}</h3>
                <ul className={css.feedback_list}>
                    {
                        Feedbackinfo.map((value) => (           
                            <li key={value.id} className={css.feedback_item} >
                                <p className={css.feedback_message}>{value.message[i18n.language]}</p>
                                <div className={css.feedback_person}>
                                    <img src={require(`assets/Feedback/${value.photo}`)} alt={value.title} className={css.feedback_image}></img>
                                    <div className={css.feedback_person_info}>
                                        <p className={css.feedback_name}>{value.name[i18n.language]}</p>
                                        <p className={css.feedback_position}>{value.position[i18n.language]}</p>
                                    </div>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    )
}
