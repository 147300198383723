import css from 'components/Home/Company/Company.module.css';
import logo from 'assets/Home/logo-big.png';
import logo2x from 'assets/Home/logo-big@2x.png';
import { useTranslation } from 'react-i18next';

import { MarkdownDisplay } from 'components/MarkdownDisplay/MarkdownDisplay'

export const Company = () => {

    const { t } = useTranslation();

    let markdownFile = "/locales/" + t("company.description");

    return (
        <section className={css.company}>
            <div className={css.company_content}>
                <img alt='IGR Software' srcSet={`${logo} 1x, ${logo2x} 2x `} className={css.company_logo}/>
                <div className={css.company_block}>
                    <h2 className={css.company_title}>{t("company.title")}</h2>
                    <MarkdownDisplay filePath={markdownFile} />
                </div>
            </div>
        </section>
    )
}