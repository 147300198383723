import { Intro } from 'components/Home/Intro/Intro';
import { Badge } from 'components/Home/Badge/Badge';
import { Company } from 'components/Home/Company/Company';
import { Service } from 'components/Service/Service';
import { Gallery } from 'components/Home/Gallery/Gallery'
import { Feedback } from 'components/Home/Feedback/Feedback';
import { Cooperation } from 'components/Home/Cooperation/Cooperation';

export const Home = () => {
    return (
        <div >
            <Intro />
            <Company />
            <Badge />
            <Service />
            <Cooperation />
            <Feedback />
            <Gallery />
        </div>
    )
}

