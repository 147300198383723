import css from 'components/Home/Cooperation/Cooperation.module.css';
import Cooperator from 'Data/Сooperation.json';
import { useTranslation } from 'react-i18next';
import 'utils/18n';

export const Cooperation = () => {

    const { t } = useTranslation();

    return (
        <section className={css.cooperation}>
            <div className={css.cooperation_content}>
            <h3 className={css.cooperation_title}>{t("cooperation.title")}</h3>
            <ul className={css.cooperation_list}>
                        {
                            Cooperator.map((value) => (           
                                <li key={value.id} className={css.cooperation_item} >
                                    <img src={require(`assets/Cooperation/${value.photo}`)} alt={value.title} className={css.cooperation_image}></img>
                                </li>
                            ))
                        }
                </ul>
            </div>
        </section>
    )
}