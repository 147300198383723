import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import css from './Modal.module.css'; 

export const Modal = ({ images, closeModal }) => {
    const settings = {
        centerMode: true,
        centerPadding: '20px',
        slidesToShow: 1,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: true,
    };

    return (
        <div className={css.modalOverlay} onClick={closeModal}>
            <div className={css.modalContent} onClick={(e) => e.stopPropagation()}>
                <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className={css.modalSlide}>
                    <img src={image} alt={`Slide ${index + 1}`} className={css.modalImage} />
                    </div>
                ))}
                </Slider>
            </div>
        </div>
        );
    };
