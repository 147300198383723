import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';


import { Spinner } from './Spinner/Spinner';
import { ScrollToTop } from './ScrollToTop/ScrollToTop';
import { Home } from 'pages/Home';
import { Services } from 'pages/Services';
import { Portfolio } from 'pages/Portfolio';
import { About } from 'pages/About';
import { Layout } from './Layout/Layout';
import { PortfolioItem } from 'pages/PortfolioItem';
import { NotFoundPage } from 'pages/NotFoundPage';

export const App = () => {
  return (
    <>
      <Suspense
        fallback={
          <>
            <Spinner/>
          </>
        }
      >
      <ScrollToTop />
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/portfolio/:item' element={<PortfolioItem />} />
            <Route path='/about' element={<About />} />
            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};
