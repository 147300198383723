import { Information } from "components/Information/Information"

export const About = () => {
    return (
        <>
            <Information />
        </>
    )
}

